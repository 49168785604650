import React from "react";
import { useEffect, useState } from "react";
import { ChatMessage, Usuario } from "../lib/types";
import { format } from 'date-fns';
import axios from "axios";

interface ChatRowProps {
  item: ChatMessage;
  usuarioId: String | undefined;
  fotoUsuario: String | undefined;
  fotoDestinatario: String | undefined;
}

const ChatRow = ({ item, usuarioId, fotoUsuario, fotoDestinatario }: ChatRowProps) => {
  const [isFromLocalUser, setIsFromLocalUser] = useState<boolean>(true);
  const [showPreview, setShowPreview] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  useEffect(() => {
    if (usuarioId !== item.senderId) {
      setIsFromLocalUser(false);
    }
  }, [usuarioId, item.senderId]);

  const formattedDate = item.createdDate
    ? format(item.createdDate, "dd/MM/yyyy '    a  las  ' HH'h'mm")
    : '';

  const handleImageClick = (image: string) => {
    setPreviewImage(image);
    setShowPreview(true);
  };

  const closePreview = () => {
    setShowPreview(false);
    setPreviewImage(null);
  };

  return (
    <>
      <li className="list-unstyled">
        <div className="col-md-12">
          {!isFromLocalUser ? (
            <>
              <div style={{ display: 'flex' }}>
                <div className="px-0">
                  <img
                    src={fotoDestinatario as string}
                    alt="Foto destinatario"
                    style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                  />
                </div>
                <div className="col-md-4 px-0">
                  <div
                    className="col-md-12 rounded ms-1 p-1"
                    style={{ backgroundColor: 'white', color: 'black' }}
                  >
                    {item.image ? (
                      <img className="imagen-chat"
                        src={item.image}
                        alt="Imagen enviada"
                        style={{ width: "300px", maxHeight: "200px", cursor: 'pointer', objectFit:'cover' }}
                        onClick={() => handleImageClick(item.image)}
                      />
                    ) : null}
                    <p className="m-0 px-2">{item.message}</p>
                  </div>
                </div>
              </div>
              <h6
                className="p-1 m-0 mb-3"
                style={{ fontSize: '12px', textAlign: 'start' }}
              >
                {formattedDate}
              </h6>
            </>
          ) : null}

          {isFromLocalUser ? (
            <>
              <div className="mensaje-derecha" style={{ display: 'flex' }}>
                <div className="col-md-4 px-0">
                  <div
                    className="col-md-12 rounded ms-1 p-1"
                    style={{ backgroundColor: 'white', color: 'black' }}
                  >
                    {item.image ? (
                      <img className="imagen-chat"
                        src={item.image}
                        alt="Imagen enviada"
                        style={{ width: "300px", maxHeight: "200px", cursor: 'pointer', objectFit:'cover' }}
                        onClick={() => handleImageClick(item.image)}
                      />
                    ) : null}
                    <p className="m-0 px-2" style={{ textAlign: 'right' }}>
                      {item.message}
                    </p>
                  </div>
                </div>
              </div>
              <h6
                className="p-1 m-0 mb-3"
                style={{ fontSize: '10px', textAlign: 'end' }}
              >
                {formattedDate}
              </h6>
            </>
          ) : null}
        </div>
      </li>

      {showPreview && previewImage && (
        <div className="modal" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}>
          <div className="modal-dialog">
            <div className="modal-content" style={{background: 'white', borderRadius:'5px', margin: '10% auto' }}>
              <div className="modal-header">
                <h5 className="modal-title" style={{color:'black'}}>Vista previa de la imagen</h5>
                <button type="button" className="btn-close" onClick={closePreview}></button>
              </div>
              <div className="modal-body">
                <img
                  src={previewImage}
                  alt="Vista previa"
                  style={{ maxWidth: "100%", maxHeight: "400px" }}
                />
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" onClick={closePreview}>Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatRow;
