import React, { useEffect, useState } from 'react';
import './credito.css';
import { API_BASE_URL, CONFIG_ID } from '../../lib/constants';
import axios from 'axios';

const Credito = ({ lastUsedCredit, creditCount, onCreditUpdated, configurationData }) =>{
  const [tiempoRestante, setTiempoRestante] = useState(calculateRemainingTime(lastUsedCredit));
  
  useEffect(() => {
   
      if (creditCount >= configurationData.creditMax) {
        setTiempoRestante(0);
        return;
      }

      const interval = setInterval(() => {
        const nuevoTiempoRestante = calculateRemainingTime(lastUsedCredit);
        setTiempoRestante(nuevoTiempoRestante);

        if (nuevoTiempoRestante <= 0) {
          onCreditUpdated();
          clearInterval(interval);
        }
      }, 1000);

      return () => clearInterval(interval);
  }, [lastUsedCredit, creditCount, onCreditUpdated, configurationData]);

  function calculateRemainingTime(lastUsedCredit) {
    console.log("tiempo "+configurationData.creditTime)
    console.log("max "+configurationData.creditMax)
    console.log("last used "+new Date(lastUsedCredit).getTime())

    const waitTime = configurationData.creditTime * 60 * 60 * 1000;

    const now=Date.now()
    const lastTime=new Date(lastUsedCredit).getTime();
    const localLastTime = lastTime + new Date().getTimezoneOffset() * 60 * 1000;

    const tiempoTranscurrido = now - localLastTime;
    const tiempoRestante = waitTime - tiempoTranscurrido;

    console.log("tiempo wt "+waitTime+"   -   tt "+tiempoTranscurrido)

    return tiempoRestante > 0 ? tiempoRestante : 0;
  }

  function formatTime(ms) {
    const totalSeconds = Math.floor(ms / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    if(hours>0)
      return `${hours}h ${minutes}m ${seconds}s`;
    else
      return `${minutes}m ${seconds}s`;

  }

  return (
    <div className="tooltip-container">
      <i className="fas fa-stopwatch" style={{color: '#00bb00', fontSize: '20px', marginLeft: '5px'}}></i>
      <div className="tooltip">
        +1 crédito en: {formatTime(tiempoRestante)}
      </div>
    </div>
  )
}

export default Credito;