import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { API_BASE_URL, CONFIG_ID, tokenBearer } from '../lib/constants';
import { Navigate } from 'react-router';

function Compra({usuario, authenticated}){
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const inputCantidadRef = useRef<HTMLInputElement>(null);
    const [configurationData, setConfigurationData] = useState({
      individualPrice: 0.0,
      subscriptionPrice: 0.00,
    });


    // Cargar configuración inicial al montar el componente
    useEffect(() => {
      const fetchBonusConfig = async () => {
        try {
          setIsLoading(true);
          const response = await axios.get(`${API_BASE_URL}/api/v1/configuration/${CONFIG_ID}`);
          const data = response.data;

          setConfigurationData(data)
        } catch (error) {
          console.error("Error fetching bonus configuration:", error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchBonusConfig();
    }, []);
    
    const handlePago = (tipo) =>{
        var sum=configurationData.subscriptionPrice;
        var cantidad=1
        if(tipo==1){
            if (inputCantidadRef.current) {
                console.log(inputCantidadRef.current.value)
                cantidad=Number(inputCantidadRef.current.value)
                sum=cantidad*configurationData.individualPrice
            }
        }

        axios.post(
            `${API_BASE_URL}/paypal/init`,
            {
                usuarioId: usuario?.id,
                sum,
                cantidad
            },
            tokenBearer
        )
        .then(response => {
            console.log(response.data.redirectUrl)
            if(response.data.status=="Success"){
                window.location.href=response.data.redirectUrl
            }
        })
    }
    if(!authenticated){
      return (
          <Navigate to="/login"/>
      );
    }

    return (
        <div>
            <section id="pricing">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="section-title">
                                <h2>Comprar &nbsp; Intentos</h2>
                            </div>
                        </div>

                        <div className="wow fadeInUp offset-md-2 col-md-4 col-sm-4" data-wow-delay="0.6s">
                            <div className="pricing-plan" style={{height: "415px"}}>
                                <div className="pricing-month">
                                    <h2>${configurationData.individualPrice}</h2>
                                </div>
                                <div className="pricing-title">
                                    <h3>Intento individual</h3>
                                </div>
                                <p>Compras sin suscribirte</p>
                                <p>1 intento adicional</p>
                                <p>Cuando lo requieras</p>
                                
                                <div className="row mt-4">
                                    <label className='offset-md-3 col-md-3' style={{fontSize:"20px"}}>Cantidad:</label>
                                    <div className="col-md-3">
                                        <input className='form-control' style={{textAlign:"right"}} type="number" min={1}  ref={inputCantidadRef} defaultValue={1} />
                                    </div>
                                </div>
                                <button type="button" className="btn btn-default section-btn mt-1" onClick={()=>handlePago(1)}>Comprar</button>
                            </div>
                        </div>

                        <div className="wow fadeInUp col-md-4 col-sm-4" data-wow-delay="0.8s">
                            <div className="pricing-plan" style={{height:"415px"}}>
                                <div className="pricing-month">
                                    <h2>${configurationData.subscriptionPrice}</h2>
                                </div>
                                <div className="pricing-title">
                                    <h3>Suscripción</h3>
                                </div>
                                <p>20 intentos</p>
                                <p>Válida por 1 mes</p>
                                <p>Soporte ilimitado</p>
                                <p>Otros beneficios</p>
                                
                                <button type="button" className="btn btn-default section-btn" onClick={handlePago.bind(2)}>Comprar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Compra;
