import React, { useEffect, useRef, useState } from "react";
import { Tab, Tabs, Nav, Form, Button, Table } from "react-bootstrap";
import axios from "axios";


//import "bootstrap/dist/css/bootstrap.min.css";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faSearch, faCogs, faUser, faGift } from "@fortawesome/free-solid-svg-icons";
import { Navigate } from "react-router";
import { API_BASE_URL, CONFIG_ID } from "../../lib/constants";


function Dashboard({authenticated}) {
  const [userCount, setUserCount]= useState<number>(0);  //cantidad de Usuarios registrados
  const [userStatsFilter, setUserStatsFilter] = useState<number>(1);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);

  
  const [searchUser, setSearchUser] = useState("");
  const [activities, setActivities] = useState([]);
  const [configurationData, setConfigurationData] = useState({
    individualPrice: 0.49,
    subscriptionPrice: 4.99,
    autoSubscribe: false,
    creditTime: 2,
    creditMax: 50
  });


  
  const handleConfigurationChange = (field, value) => {
    setConfigurationData((prev) => ({ ...prev, [field]: value }));
  }
  const handleSearchActivities = (field, value) => {
    setConfigurationData((prev) => ({ ...prev, [field]: value }));
  };


  // Cargar configuración inicial al montar el componente
  useEffect(() => {
    const fetchBonusConfig = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${API_BASE_URL}/api/v1/configuration/${CONFIG_ID}`);
        const data = response.data;

        setConfigurationData(data)
      } catch (error) {
        console.error("Error fetching bonus configuration:", error);
        setMessage("Error cargando configuración.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchBonusConfig();
  }, []);

  // Cargar de todos los usuario
  useEffect(() => {
    const fetchBonusConfig = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${API_BASE_URL}/api/v1/users/count?filter=${userStatsFilter}`);
        const data = response.data;

        console.log("data "+data)
        setUserCount(data);
      } catch (error) {
        console.error("Error fetching bonus configuration:", error);
        setMessage("Error cargando configuración.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchBonusConfig();
  }, [userStatsFilter]);

  // Guardar configuración
  const handleSaveConfiguration = async () => {
    try {
      setIsLoading(true);
      //const payload = { credit, creditMax };
      await axios.post(`${API_BASE_URL}/api/v1/configuration`, configurationData);

      setMessage("Configuración guardada exitosamente.");
    } catch (error) {
      console.error("Error saving bonus configuration:", error);
      setMessage("Error guardando configuración.");
    } finally {
      setIsLoading(false);
    }
  };






  if(!authenticated){
    return (
        <Navigate to="/login"/>
    );
  }

  return (
    <div className="container" style={{background:'#535bd4', minHeight:'500px'}}>
      <Tabs defaultActiveKey="stats" id="dashboard-tabs" className="mb-3">
        {/* Ficha 1: Estadísticas */}
        <Tab eventKey="stats" title={<> *  Estadísticas</>}>
          <Form.Group controlId="userStatsFilter" className="mb-3">
            <Form.Label>Filtrar registros</Form.Label>
            <Form.Select
              value={userStatsFilter}
              onChange={(e) => setUserStatsFilter(e.target.value)}
            >
              <option value="4">Hoy</option>
              <option value="3">Semana</option>
              <option value="2">Mes</option>
              <option value="1">Últimos 6 meses</option>
            </Form.Select>
          </Form.Group>
          <p>Estadísticas de usuarios registrados: {userCount}</p>
        </Tab>

        {/* Ficha 2: Actividad */}
        <Tab eventKey="activity" title={<> * Actividad</>}>
          <Form.Group controlId="searchUser" className="mb-3">
            <Form.Label>Buscar usuario</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese el nombre del usuario"
              value={searchUser}
              onChange={(e) => setSearchUser(e.target.value)}
            />
          </Form.Group>
          <Button variant="primary" onClick={handleSearchActivities}>
            Buscar actividades
          </Button>
          <Table striped bordered hover className="mt-3">
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Tipo de Actividad</th>
                <th>Descripción</th>
              </tr>
            </thead>
            <tbody>
              {activities.map((activity, index) => (
                <tr key={index}>
                  <td>{activity.date}</td>
                  <td>{activity.type}</td>
                  <td>{activity.description}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Tab>

        {/* Ficha 3: Configuración de Pagos */}
        <Tab eventKey="payments" title={<> * Configuración de Pagos</>}>
          <Form.Group controlId="individualPrice" className="mb-3">
            <Form.Label>Pago Individual</Form.Label>
            <Form.Control
              type="number"
              placeholder="Precio"
              value={configurationData.individualPrice}
              onChange={(e) => handleConfigurationChange("individualPrice", e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="subscriptionPrice" className="mb-3">
            <Form.Label>Pago Suscripción Mensual</Form.Label>
            <Form.Control
              type="number"
              placeholder="Precio"
              value={configurationData.subscriptionPrice}
              onChange={(e) => handleConfigurationChange("subscriptionPrice", e.target.value)}
            />
          </Form.Group>
          <Form.Check
            type="checkbox"
            label="Suscribirme para pagos automáticos"
            checked={configurationData.autoSubscribe}
            onChange={(e) => handleConfigurationChange("autoSubscribe", e.target.checked)}
          />
          <Button onClick={()=>handleSaveConfiguration()}>Guardar</Button>
        </Tab>
        {/* Ficha 4: Configuración de Bono */}
        <Tab eventKey="bonus" title={<> *   Configuración de Bono</>}>
          <Form.Group controlId="creditTime" className="mb-3">
            <Form.Label>Tiempo para obtener un crédito</Form.Label>
            <Form.Control
              type="number"
              min={1}
              step={1}
              value={configurationData.creditTime}
              onChange={(e) => handleConfigurationChange("creditTime", e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="creditMax" className="mb-3">
            <Form.Label>Máximo Bono</Form.Label>
            <Form.Control
              type="number"
              min={0}
              max={100}
              value={configurationData.creditMax}
              onChange={(e) => handleConfigurationChange("creditMax", e.target.value)}
            />
          </Form.Group>
          <Button onClick={()=>handleSaveConfiguration()}>Guardar</Button>
        </Tab>
      </Tabs>
    </div>
  );
}

export default Dashboard;
