import React from "react";
import { ChangeEvent, KeyboardEvent, MouseEvent, useState } from "react";
import { API_BASE_URL } from "../lib/constants";

interface ChatFormProps {
  room: Room | undefined;
  usuarioId: String;
}

const ChatForm = ({ room, usuarioId }: ChatFormProps) => {
  const [message, setMessage] = useState("");
  const [image, setImage] = useState<string | null>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result as string);
        setPreview(reader.result as string);
        setShowModal(true);
      };
      reader.readAsDataURL(file);
    }
  };

  async function addMessage(roomId: string | undefined, message: string, image: string | null) {
    const data = await fetch(`${API_BASE_URL}/chats`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify({
        roomId,
        message,
        image,
        senderId: usuarioId,
        receiverId: room?.destinatarioId,
      }),
    });
    return data.status;
  }

  const sendMessage = () => {
    if (message !== "" || image !== null) {
      addMessage(room?.roomId, message, image);
      setMessage("");
      setImage(null);
      setPreview(null);
      setShowModal(false);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };

  const handleSubmit = (e: MouseEvent) => {
    e.preventDefault();
    sendMessage();
  };

  return (
    <div className="col-md-12">
      <form className="col-md-12">
        <div className="form-group col-md-11">
          <div className="d-flex">
            <div
              className="offset-md-1 col-md-8 ps-5 input-message"
              style={{ alignContent: "center" }}
            >
              <input
                className="form-control p-2"
                id="message"
                type="text"
                placeholder="Escribe un mensaje o selecciona una imagen"
                value={message}
                onKeyDown={handleKeyDown}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-2 d-flex">
              <input
                className="form-control p-1 me-2"
                id="image"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
              <button
                className="btn btn-success"
                onClick={handleSubmit}
                type="submit"
              >
                <i className="fa fa-arrow-right" style={{ fontSize: "20px" }}></i>
              </button>
            </div>
          </div>
        </div>
      </form>

      {showModal && preview && (
        <div className="modal" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}>
          <div className="modal-dialog">
            <div className="modal-content" style={{backgroundColor:'white', borderRadius: '5px'}}>
              <div className="modal-header">
                <h5 className="modal-title" style={{color: 'black'}}>Vista previa de la imagen</h5>
                <button type="button" className="btn-close" onClick={() => setShowModal(false)}></button>
              </div>
              <div className="modal-body">
                <img
                  src={preview}
                  alt="Vista previa"
                  style={{ maxWidth: "100%", maxHeight: "400px" }}
                />
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" onClick={() => setShowModal(false)}>Cerrar</button>
                <button className="btn btn-primary" onClick={sendMessage}>Enviar</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatForm;
